console.log(
  '%c MUHAMMAD JAVED',
  `
    background-color: black;
    color: white;
    font-size: 2vw;
    line-height: 1;
    padding: 2rem 6vw;
  `
)
